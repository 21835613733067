import { useState } from 'react';
import { Button, Divider } from 'antd';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';

import { getSessionId } from '@/service';
import { IconRcChyshen, IconRcChyshenZh } from '@/asset';
import { CHAIN_LIST } from '@/const';
import { formatReadable, request } from '@/util';

import { LoginModal } from './component';
import './index.less';

const USER_COUNT = 2353000;

const Welcome = () => {
  const [loginModalOpen, setLoginModalOpen] = useState(false);
  const history = useHistory();
  const sessionId = getSessionId();
  const { data: waitCount } = useQuery(['user', 'total_count'], () =>
    request.get<number>('/user/total_count', null, { noSession: true })
  );

  const { data: projectCount } = useQuery(['user', 'project_count'], () =>
    request.get<number>('/user/project_count', null, { noSession: true })
  );

  const handleTopBtnClick = () => {
    if (sessionId) {
      history.push('/questionnaire');
    } else {
      setLoginModalOpen(true);
    }
  };

  const handleJoinClick = () => {
    setLoginModalOpen(true);
  };

  const handleLoginModalClose = () => {
    setLoginModalOpen(false);
  };

  return (
    <div className="bg-gray-dark overflow-hidden lg:h-screen sm:h-[950px]">
      <div className="max-w-[1160px] mx-auto h-full lg:flex flex-col text-white px-20">
        <header className="flex justify-between lg:mt-80 sm:mt-24 relative">
          <div>
            <IconRcChyshenZh className="inline-block text-primary lg:text-36 sm:text-24" />
            <IconRcChyshen className="inline-block text-white lg:text-28 sm:text-20 lg:ml-10 sm:ml-6" />
            <span
              className="text-13 text-white text-opacity-40 ml-4"
              style={{ verticalAlign: 'top', lineHeight: '1px' }}>
              Launching soon
            </span>
          </div>
          <Button
            ghost
            className="border-white border-opacity-30 text-white text-opacity-70 absolute right-0 top-0 z-20"
            onClick={handleTopBtnClick}>
            {sessionId ? 'dashboard' : 'login'}
          </Button>
        </header>
        <main className="flex-1 welcome-main relative lg:flex items-center justify-between">
          <section className="relative z-10 lg:text-[42px] sm:text-[30px] font-bold sm:text-center sm:mt-70 sm:mb-60">
            Exclusive in-depth on-chain portfolio analysis from
            <span className="text-primary">
              {' '}
              {formatReadable(USER_COUNT)}+{' '}
            </span>
            DeFi users across
            <span className="text-primary">
              {' '}
              {formatReadable(projectCount)}{' '}
            </span>
            protocols
          </section>
          <section className="join-form sm:mb-80">
            <div className="join-form-inner">
              <div className="mt-36 text-[48px] font-bold">
                {formatReadable(waitCount)}
              </div>
              <div>ON THE WAITING LIST</div>
              <Button
                className="mt-24 w-[210px] h-[46px] font-bold text-16"
                type="primary"
                onClick={handleJoinClick}>
                Join the waiting list
              </Button>
            </div>
          </section>
        </main>
        <footer className="flex-shrink-0 lg:pb-[98px] sm:pb-20 relative z-10">
          <section className="flex flex-wrap w-full text-white text-opacity-40 sm:justify-center">
            {CHAIN_LIST.map(({ Icon, name }) => (
              <div className="flex items-center mb-12 lg:mr-32 sm:mr-12" key={name}>
                <Icon key={name} className="text-24" />
                <span className="text-12 ml-2">{name}</span>
              </div>
            ))}
          </section>
          <Divider className="bg-white bg-opacity-10 mt-8 mb-20" />
          <section className="text-white text-opacity-60 font-semibold sm:text-center">
            Powered by DeBank
          </section>
        </footer>
      </div>
      <LoginModal visible={loginModalOpen} onCancel={handleLoginModalClose} />
    </div>
  );
};

export default Welcome;
