import { useState, useEffect, useCallback } from 'react';

export enum DEVICE_TYPE {
  PC,
  MOBILE,
}

const MAX_MOBILE_WIDTH = 767;

let type = getDeviceType();

export let isMobile = type === DEVICE_TYPE.MOBILE;

export function getDeviceType() {
  // include sidebar width
  if (window.outerWidth <= MAX_MOBILE_WIDTH) {
    return DEVICE_TYPE.MOBILE;
  } else {
    return DEVICE_TYPE.PC;
  }
}

export function useDeviceType() {
  const [_type, setType] = useState(type);

  const handleResize = useCallback(() => {
    setType(getDeviceType());
  }, [setType]);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [handleResize]);

  const isMobile = _type === DEVICE_TYPE.MOBILE;

  return [isMobile, _type] as const;
}
