import { PageHeader, PageFooter } from '@/component';
import { useUser } from '@/hook';
import { queryClient } from '@/util';

import { PriceVote, Features, Invitation } from './component';

const Questionnaire = () => {
  const { data } = useUser();

  // private route had blocked invalid useUser
  const { estimate_price, features, invite_code, invite_count } = data || {};

  const handleDataMutate = (part: any) => {
    queryClient.setQueryData('user', { ...data, ...part });
  };

  return (
    <div className="flex flex-col h-full w-full">
      <PageHeader />
      <div className="flex-1 bg-gray-backdrop pb-40">
        <main className="lg:w-860 mx-auto">
          <section
            className={
              'border border-primary border-opacity-60 bg-primary bg-opacity-6 flex items-center text-primary font-medium ' +
              'lg:my-40 lg:pl-32 lg:h-70 ' +
              'sm:mx-20 sm:my-24 sm:p-16'
            }>
            <div>
              <span className="mr-10">🎉</span>You are on the waiting list and
              will be the early birds to experience Chyshen when it's live!
            </div>
          </section>
          <PriceVote price={estimate_price} onPriceSet={handleDataMutate} />
          <Features features={features} onFeaturesSet={handleDataMutate} />
          <Invitation inviteCode={invite_code} inviteCount={invite_count} />
        </main>
      </div>
      <PageFooter />
    </div>
  );
};

export default Questionnaire;
