import { useCallback } from 'react';
import { Divider, Slider, Button, message } from 'antd';
import { useMutation } from 'react-query';

import { IconRcVoteTipBorder, IconRcVoteVerticalTipBorder } from '@/asset';
import { request } from '@/util';
import { useDeviceType } from '@/hook';

import './index.css';

const MARKS = {
  0: '$100',
  25: '$500',
  50: '$1,000',
  75: '$5,000',
  100: '$10,000',
};

// [[0, 100, (500 - 100) / (25 -0)]] === [[mark, price, stepPrice]]
const MARKS_LIST = Object.entries(MARKS)
  .map(([k, v]) => [Number(k), Number(v.replace(/\$|,/g, ''))])
  .map(([k, v], i, arr) => [
    k,
    v,
    i === arr.length - 1 ? 0 : (arr[i + 1][1] - v) / (arr[i + 1][0] - k),
  ]);

const DisabledSliderTip = ({ price = 0 }) => {
  const [isMobile] = useDeviceType();

  const Border = isMobile ? IconRcVoteVerticalTipBorder : IconRcVoteTipBorder;

  return (
    <div className="vote-slider-tip pt-0 text-left flex items-center justify-center">
      <Border className="absolute z-[-1] top-0" />
      <div>
        <div className="text-12 text-primary">Your vote</div>
        <div>
          <span className="text-18 font-bold">${price}</span>
          <span className="text-15">/month</span>
        </div>
      </div>
    </div>
  );
};

const SliderTip = ({
  progress = 0,
  onPriceSet,
}: {
  progress?: number;
  onPriceSet: (arg: { estimate_price: number }) => void;
}) => {
  const [isMobile] = useDeviceType();

  const { isLoading, mutate } = useMutation(
    (price: string) => request.post('/suggest/estimate_price', { price }),
    {
      onSuccess(_, price) {
        message.success('Voted');
        onPriceSet({ estimate_price: Number(price) });
      },
    }
  );
  const Border = isMobile ? IconRcVoteVerticalTipBorder : IconRcVoteTipBorder;
  const rightMarkIndex = MARKS_LIST.findIndex(([x]) => x >= progress)!;

  let currentPrice = MARKS_LIST[0][1]; // 0 point
  // exclude under min / overflow max
  if (rightMarkIndex > 0) {
    const [leftMark, leftMarkPrice, stepPrice] = MARKS_LIST[rightMarkIndex - 1];
    currentPrice = (progress - leftMark) * stepPrice + leftMarkPrice;
  }

  const price = currentPrice.toFixed();

  const handleSubmit = (event: any) => {
    mutate(price);
    event.stopPropagation();
    event.preventDefault();
  };

  const disableTipDrag = useCallback((event) => {
    event.stopPropagation();
  }, []);

  return (
    <div className="vote-slider-tip">
      {/* can't use css triangle cause of opacity background will mixed */}
      <Border className="absolute z-[-1] top-0" />
      <div className="pt-12">
        <span className="text-18 font-bold">${price}</span>
        <span className="text-15">/month</span>
      </div>
      <Button
        loading={isLoading}
        className="font-semibold text-12 mt-8"
        type="primary"
        onPointerDown={disableTipDrag}
        onPointerMove={disableTipDrag}
        onPointerUp={disableTipDrag}
        onPointerCancel={disableTipDrag}
        onTouchStart={disableTipDrag}
        onTouchMove={disableTipDrag}
        onTouchEnd={disableTipDrag}
        onTouchCancel={disableTipDrag}
        onMouseDown={disableTipDrag}
        onMouseMove={disableTipDrag}
        onMouseUp={disableTipDrag}
        // onClick={disableTipDrag}
        onClickCapture={handleSubmit}>
        Submit
      </Button>
    </div>
  );
};

type SliderTipProps = {
  price?: number;
  onPriceSet: (arg: { estimate_price: number }) => void;
};

export const PriceVote = ({ price, onPriceSet }: SliderTipProps) => {
  const [isMobile] = useDeviceType();
  const isPriceSet = !!price;
  const _price = price || 1000;

  let progress = 0; // 0 point
  const rightMarkIndex = MARKS_LIST.findIndex(([, x]) => x >= _price)!;
  // exclude under min / overflow max
  if (rightMarkIndex > 0) {
    const [leftMark, leftMarkPrice, stepPrice] = MARKS_LIST[rightMarkIndex - 1];
    progress = (_price - leftMarkPrice) / stepPrice + leftMark;
  }

  return (
    <article className="bg-white pt-24 pb-20 shadow lg:mb-40 sm:mb-24 lg:px-32 sm:px-20">
      <h1 className="leading-none text-20 font-semibold lg:mb-[150px] sm:mb-[44px]">
        How much do you think this product should be priced?
      </h1>
      <Slider
        key={price}
        className="vote-slide sm:h-[360px]"
        marks={MARKS}
        vertical={isMobile}
        defaultValue={progress}
        tooltipVisible
        tipFormatter={
          isPriceSet
            ? () => <DisabledSliderTip price={price} />
            : (progress) => (
                <SliderTip progress={progress} onPriceSet={onPriceSet} />
              )
        }
        getTooltipPopupContainer={(triggerNode) => triggerNode}
        step={0.1}
        disabled={isPriceSet}
      />
      <Divider className="bg-gray-dark bg-opacity-10 lg:mt-40 sm:mt-60" />
      <section className="text-gray-dark text-opacity-50 mt-[9px]">
        The closet guess (top 10%) will receive an extra bonus
      </section>
    </article>
  );
};
