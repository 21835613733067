import { useEffect } from 'react';
import {
  Route,
  Switch,
  BrowserRouter as Router,
  Redirect,
  useLocation,
} from 'react-router-dom';
import { QueryClientProvider } from 'react-query';
import ReactGA from 'react-ga';
import { ConfigProvider } from 'antd';

import { queryClient } from '@/util';
import { PrivateRoute } from '@/component';

import Welcome from './Welcome';
import Questionnaire from './Questionnaire';

ReactGA.initialize('UA-207757765-1');

const LogPageView = () => {
  const location = useLocation();

  useEffect(() => {
    const page = `${location.pathname}${location.search}`;
    ReactGA.set({
      page: `${page}`,
      location: `${origin}${page}`,
    });

    ReactGA.pageview(page);
    // eslint-disable-next-line
  }, [location.pathname]);

  return null;
};

const App = () => {
  return (
    <ConfigProvider>
      <QueryClientProvider client={queryClient}>
        <Router>
          <LogPageView />
          <Switch>
            <Route exact path="/">
              <Welcome />
            </Route>
            <PrivateRoute exact path="/questionnaire">
              <Questionnaire />
            </PrivateRoute>
            <Route>
              <Redirect to="/" />
            </Route>
          </Switch>
        </Router>
      </QueryClientProvider>
    </ConfigProvider>
  );
};

export default App;
