import {
  IconRcArbitrum,
  IconRcAvalanche,
  IconRcBsc,
  IconRcEthereum,
  IconRcFantom,
  IconRcHeco,
  IconRcOkex,
  IconRcOptimism,
  IconRcPolygon,
  // IconRcSolana,
  IconRcXdai,
  IconRcCelo,
} from '@/asset/chain';

// chain's "id" for api request using
export const CHAIN_DICT = {
  ETHEREUM: { chain: 'eth', Icon: IconRcEthereum, name: 'Ethereum' },
  BSC: { chain: 'bsc', Icon: IconRcBsc, name: 'BSC' },
  XDAI: { chain: 'xdai', Icon: IconRcXdai, name: 'xDai' },
  POLYGON: { chain: 'matic', Icon: IconRcPolygon, name: 'Polygon' },
  FANTOM: { chain: 'ftm', Icon: IconRcFantom, name: 'Fantom' },
  OKEX: { chain: 'okt', Icon: IconRcOkex, name: 'OKExChain' },
  HECO: { chain: 'heco', Icon: IconRcHeco, name: 'HECO' },
  AVALANCHE: {
    chain: 'avax',
    Icon: IconRcAvalanche,
    name: 'Avalanche',
  },
  ARBITRUM: { chain: 'arb', Icon: IconRcArbitrum, name: 'Arbitrum' },
  OPTIMISM: { chain: 'op', Icon: IconRcOptimism, name: 'Optimism' },
  CELO: { chain: 'celo', Icon: IconRcCelo, name: 'Celo' },
  // SOLANA: { chain: 'solana', Icon: IconRcSolana, name: 'Solana' },
};

export type CHAIN_ENUM = keyof typeof CHAIN_DICT;

export const CHAIN_LIST = Object.values(CHAIN_DICT);
