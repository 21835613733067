import { Route, Redirect, RouteProps } from 'react-router-dom';
import { Spin } from 'antd';

import { useUser } from '@/hook';
import { LOGIN_URL } from '@/const';

export const PrivateRoute = ({ children, ...rest }: RouteProps) => {
  const { data, isSuccess, isLoading } = useUser();

  return (
    <Route
      {...rest}
      render={({ location }) => (
        <Spin spinning={isLoading}>
          {!isLoading && !isSuccess && !data?.id && (
            <Redirect
              to={{
                pathname: LOGIN_URL,
                state: { from: location },
              }}
            />
          )}
          {children}
        </Spin>
      )}
    />
  );
};
