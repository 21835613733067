import { Button } from 'antd';
import { useHistory, Link } from 'react-router-dom';

import { IconRcChyshen, IconRcChyshenZh } from '@/asset';
import { request, queryClient } from '@/util';
import { useUser } from '@/hook';
import { setSessionId } from '@/service';
import { LOGIN_URL } from '@/const';

export const PageHeader = () => {
  const history = useHistory();
  const { data } = useUser();

  const handleLogoutClick = () => {
    request.get('/user/logout').then(() => {
      setSessionId('');
      queryClient.clear();
      history.replace(LOGIN_URL);
    });
  };

  return (
    <header className="border-b border-opacity-10 border-gray-dark">
      <div className="lg:w-860 mx-auto flex justify-between items-center lg:h-70 sm:h-[64px] sm:px-20">
        <Link to="/" className="flex items-center">
          <IconRcChyshenZh className="inline-block text-primary lg:text-36 sm:text-24" />
          <IconRcChyshen className="inline-block text-gray-dark lg:text-28 sm:text-20 ml-10" />
        </Link>
        <div className="text-gray-mid">
          {data?.email}
          <span className="text-gray-border ml-8">|</span>
          <Button
            size="small"
            type="text"
            className="text-gray-mid"
            onClick={handleLogoutClick}>
            Logout
          </Button>
        </div>
      </div>
    </header>
  );
};
