import { useEffect, useRef, useState } from 'react';

export const useCountDown = (totalSeconds = 60) => {
  const timer = useRef(-1);
  const [seconds, setSeconds] = useState(0);

  const clearTimer = () => {
    if (timer.current !== -1) {
      clearTimeout(timer.current);
      timer.current = -1;
    }
  };

  const startCountDown = () => {
    setSeconds(totalSeconds);
  };

  const stopCountDown = () => {
    setSeconds(0);
  };

  useEffect(() => {
    if (!seconds) {
      clearTimer();
      return;
    }

    timer.current = window.setTimeout(() => {
      setSeconds((secs) => secs - 1);
    }, 1000);

    return clearTimer;
  }, [seconds]);

  return [seconds, startCountDown, stopCountDown] as const;
};
