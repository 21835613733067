import { useState } from 'react';

export const useQueryString = (key: string) => {
  const qs = new URLSearchParams(window.location.search);
  const [value, setValue] = useState(qs.get(key) || undefined);

  const setQs = (value: string) => {
    qs.set(key, value);
    setValue(value);
    const url = `${window.location}?${qs.toString()}`;

    window.history.replaceState(window.history.state, '', url);
    return;
  };

  return [value, setQs] as const;
};
