/**
 * make localstorage corresponding to object
 *
 * undefined will be stringified to null
 */

const createProxy = <T extends Record<string | symbol, any>>(
  obj: T,
  setCall: (...arg: any) => any,
  proxyCache = new Map()
): T =>
  new Proxy(obj, {
    get(target, prop) {
      const originValue = target[prop];
      const targetPath = proxyCache.get(target)?.[0] || [];

      if (
        ['[object Object]', '[object Array]'].indexOf(
          Object.prototype.toString.call(originValue)
        ) !== -1
      ) {
        if (!proxyCache.has(originValue)) {
          targetPath.push(prop);
          proxyCache.set(originValue, [
            targetPath,
            createProxy(originValue, setCall, proxyCache),
          ]);
        }

        const [, proxyValue] = proxyCache.get(originValue);

        return proxyValue;
      }

      return originValue;
    },
    set(target, prop: keyof T, value) {
      target[prop] = value;
      const targetPath = proxyCache.get(target)?.[0] || [];
      setCall(targetPath.concat(prop), value);

      return true;
    },

    deleteProperty(target, prop: keyof T) {
      if (Reflect.has(target, prop)) {
        const targetPath = proxyCache.get(target)?.[0] || [];
        setCall(targetPath.concat(prop));
        Reflect.deleteProperty(target, prop);
      }

      return true;
    },
  });

const storageDict: Record<string, any> = {};

let i = 0;
let key: null | string = '';

while ((key = localStorage.key(i))) {
  const value = localStorage.getItem(key);
  try {
    // take "undefined" as null, cause JSON.stringify([undefined]) === [null]
    storageDict[key] = 'undefined' === value ? null : JSON.parse(value!);
  } catch {
    storageDict[key] = value;
  }
  i++;
}

export const storage = createProxy(storageDict, ([rootPath]) => {
  try {
    localStorage.setItem(rootPath, JSON.stringify(storage[rootPath]));
  } catch (error) {
    console.log('localstorage set error', error);
  }
});
