import { useCallback } from 'react';
import { atom, useRecoilState } from 'recoil';
import { ConfigProvider } from 'antd';

import 'antd/dist/antd.variable.less';

// eslint-disable-next-line
import { HASHED_THEME } from '../style/theme';

enum THEMES {
  DEFAULT = 'light',
  DARK = 'dark',
}

const defaultTheme = THEMES.DEFAULT;

const themeState = atom({
  key: 'themeState',
  default: defaultTheme,
});

export const loadTheme = (theme = defaultTheme) => {
  const rootElement =
    document.documentElement || document.querySelector<HTMLElement>('html');

  rootElement.setAttribute('theme', theme);

  const themeVariables = HASHED_THEME[theme as keyof typeof HASHED_THEME];

  ConfigProvider.config({
    theme: {
      primaryColor: themeVariables.primaryColor,
    },
  });
};

export const useTheme = () => {
  const [theme, setTheme] = useRecoilState(themeState);
  const isDark = theme === THEMES.DARK;

  const toggleTheme = useCallback(() => {
    const nextTheme = isDark ? THEMES.DEFAULT : THEMES.DARK;

    setTheme(nextTheme);
    loadTheme(nextTheme);
  }, [isDark, setTheme]);

  return {
    isDark,
    toggleTheme,
  };
};
