import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import { loadTheme } from '@/hook';
import App from '@/view';
import '@/style/index.less';

loadTheme();

Sentry.init({
  dsn: 'https://1a5db4ee4b66423ba9935986a3fc562a@o460488.ingest.sentry.io/5948571',
  integrations: [new Integrations.BrowserTracing()],
  release: process.env.version,

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

ReactDOM.render(<App />, document.getElementById('root'));
