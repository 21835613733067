import { useState } from 'react';
import { Modal, ModalProps, Form, Input, Button, Row, Col } from 'antd';
import { useHistory } from 'react-router-dom';
import { useMutation } from 'react-query';

import { request } from '@/util';
import { useQueryString, useCountDown } from '@/hook';
import { setSessionId } from '@/service';

type LoginModalProps = {
  visible: boolean;
  onCancel: ModalProps['onCancel'];
};

const COUNT_DOWN_SECONDS = 60;

type LoginParams = {
  email: string;
  verify_code: string;
};

export const LoginModal = ({ visible, onCancel }: LoginModalProps) => {
  const history = useHistory();
  const [inviteCode] = useQueryString('invite-code');
  const [seconds, startCountDown] = useCountDown(COUNT_DOWN_SECONDS);
  const [verifyCodeExtra, setVerifyCodeExtra] = useState<string | null>(null);
  const [form] = Form.useForm();

  const { isLoading, mutate: submitLogin } = useMutation(
    (data: LoginParams) =>
      request.post<{ session_id: string }>('/user/login', data, {
        noSession: true,
      }),
    {
      onSuccess({ session_id }) {
        setSessionId(session_id);
        history.push('/questionnaire');
      },
      onError(err: any) {
        setVerifyCodeExtra(null);
        form.setFields([
          {
            name: 'verify_code',
            errors: [err?.error_msg?.en || 'invalid verify code'],
          },
        ]);
      },
    }
  );

  const handleLogin = ({ email, verify_code }: LoginParams) => {
    const data = { email, verify_code };

    if (inviteCode) {
      Object.assign(data, {
        invite_code: inviteCode,
      });
    }

    submitLogin(data);
  };

  const handleVerifyClick = async () => {
    try {
      const { email } = await form.validateFields(['email']);
      await request.get(
        '/user/verify_code',
        { email },
        {
          noSession: true,
        }
      );
      startCountDown();
      setVerifyCodeExtra(
        'Please check your email and get the verification code'
      );
    } catch (err: any) {
      // invalid email
      if (err.error_code === 1) {
        form.setFields([
          {
            name: 'email',
            errors: ['Only supports Gmail for now'],
          },
        ]);
      }
      if (err.url) {
        form.setFields([
          {
            name: 'verify_code',
            errors: ['Network error, try again later'],
          },
        ]);
      }
    }
  };

  return (
    <Modal
      className="lg:w-[540px] sm:w-[334px]"
      title={null}
      centered
      closable={false}
      visible={visible}
      footer={null}
      onCancel={onCancel}
      maskClosable>
      <div className="bg-gray-background lg:py-40 lg:px-[56px] sm:p-20">
        <Form layout="vertical" onFinish={handleLogin} form={form}>
          <Form.Item
            label={<span className="text-16 text-gray-dark">Email</span>}>
            <Form.Item
              name="email"
              noStyle
              validateTrigger="onBlur"
              rules={[
                {
                  required: true,
                  message: 'required',
                },
                {
                  type: 'email',
                  message: 'Only supports Gmail for now',
                },
              ]}>
              <Input size="large" placeholder="mail@gmail.com" />
            </Form.Item>
          </Form.Item>
          <Form.Item
            label={
              <span className="text-16 text-gray-dark">
                Email verification code
              </span>
            }
            help={
              verifyCodeExtra && (
                <span className="text-gray-dark">{verifyCodeExtra}</span>
              )
            }>
            <Row gutter={16}>
              <Col className="gutter-row" span={15}>
                <Form.Item
                  name="verify_code"
                  noStyle
                  rules={[
                    {
                      required: true,
                      message: 'required',
                    },
                  ]}>
                  <Input size="large" placeholder="Verification code" />
                </Form.Item>
              </Col>
              <Col className="gutter-row" span={9}>
                <Button
                  disabled={!!seconds}
                  type="primary"
                  ghost
                  size="large"
                  block
                  className="bg-primary bg-opacity-6 font-semibold whitespace-pre-wrap p-0"
                  onClick={handleVerifyClick}>
                  Get code {!!seconds && `(${seconds})`}
                </Button>
              </Col>
            </Row>
          </Form.Item>
          <Button
            loading={isLoading}
            type="primary"
            htmlType="submit"
            size="large"
            className="w-[46%] lg:mt-24 sm:mt-6 lg:mb-20 sm:mb-10 text-18 font-semibold">
            Submit
          </Button>
        </Form>
      </div>
    </Modal>
  );
};
