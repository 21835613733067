import { LOGIN_URL } from '@/const';
import { getSessionId, setSessionId } from '@/service';

const BASE_URL =
  process.env.NODE_ENV === 'production'
    ? 'https://api.chyshen.com'
    : 'https://api.chyshen.com';
// : 'https://alpha-api.chyshen.com';

type RequestOptions = {
  timeout?: number;
  base_url?: string;
  data?: RequestInit['body'] | Record<string, string> | null;
  noSession?: boolean;
  abortController?: AbortController;
} & Pick<RequestInit, 'headers' | 'method' | 'body'>;

const _request = (url: string, options: RequestOptions = {}) => {
  const {
    timeout,
    headers,
    base_url,
    data,
    method,
    noSession,
    abortController,
    ...rest
  } = options;

  const _headers: HeadersInit = { ...headers };

  const sessionid = getSessionId();
  if (sessionid && !noSession) {
    Object.assign(_headers, {
      account: JSON.stringify({ session_id: sessionid }),
    });
  }

  const requestAbort = new AbortController();

  // hook pass in abort to request abortController
  if (abortController) {
    if (abortController.signal.aborted) {
      requestAbort.abort();
    }
    abortController.signal.addEventListener('abort', () => {
      requestAbort.abort();
    });
  }

  const fetchOptions: RequestInit = {
    ...rest,
    headers: _headers,
    method,
    signal: requestAbort.signal,
  };

  let resource = `${base_url || BASE_URL}${url}`;
  const isDataValid = data && Object.keys(data).length;

  if (method === 'GET' && isDataValid) {
    const qs = new URLSearchParams(data as Record<string, string>);
    resource += `?${qs.toString()}`;
  } else if (
    method === 'POST' &&
    isDataValid &&
    (_headers as Record<string, string>)['Content-Type'] ===
      'application/x-www-form-urlencoded'
  ) {
    const qs = new URLSearchParams(data as Record<string, string>);

    fetchOptions.body = qs.toString();
  } else {
    fetchOptions.body = data as BodyInit;
  }

  let abortTimer: number;
  if (timeout) {
    abortTimer = window.setTimeout(() => {
      requestAbort.abort();
    }, timeout);
  }

  return fetch(resource, fetchOptions)
    .then((res) => {
      if (timeout) {
        clearTimeout(abortTimer);
      }
      return res;
    })
    .then((res) => {
      if (res.ok) {
        return res.json();
      }
      return Promise.reject(res);
    });
};

const checkErrorcode = (res: { error_code: number; data?: any }) => {
  if (res.error_code === 0) {
    return res.data;
  }

  if (res.error_code === 401) {
    setSessionId('');
    window.history.replaceState(
      { from: window.location.pathname },
      '',
      LOGIN_URL
    );
  }

  return Promise.reject(res);
};

const get = <T>(
  url: string,
  data?: Record<string, string> | null,
  options?: RequestOptions
): Promise<T> =>
  _request(url, {
    data,
    method: 'GET',
    ...options,
  }).then(checkErrorcode);

const post = <T>(
  url: string,
  data?: any,
  options?: RequestOptions
): Promise<T> =>
  _request(url, {
    data,
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    ...options,
  }).then(checkErrorcode);

export const request = { get, post };

export default request;
