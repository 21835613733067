const THEME_COLOR = {
  light: {
    primaryColor: '176, 85, 85',
    whiteColor: '255, 255, 255',
    blackColor: '0, 0, 0',
    grayDarkColor: '0, 9, 24',
    grayBorderColor: '225, 229, 242',
    grayBackgroundColor: '239, 243, 248',
    grayLightColor: '139, 147, 167',
    grayBackdropColor: '250, 251, 252',
    grayMidColor: '97, 107, 132',
    graySliderColor: '232, 233, 237',
  },
  dark: {
    primaryColor: '176, 85, 85',
    whiteColor: '255, 255, 255',
    blackColor: '0, 0, 0',
    grayDarkColor: '0, 9, 24',
    grayBorderColor: '225, 229, 242',
    grayBackgroundColor: '239, 243, 248',
    grayLightColor: '139, 147, 167',
    grayBackdropColor: '250, 251, 252',
    grayMidColor: '97, 107, 132',
    graySliderColor: '232, 233, 237',
  },
};

const convert2Css = (theme: typeof THEME_COLOR['light']) => {
  return Object.entries(theme)
    .map(([key, color]) => {
      const _key = key.replace(/([A-Z])/g, (a, p1) => `-${p1.toLowerCase()}`);
      return `--${_key}:${color}`;
    })
    .join(';');
};

const addStyle = (cssText: string) => {
  const style = document.createElement('style');
  style.textContent = cssText;
  document.head.append(style);
};

const themeStyle = Object.entries(THEME_COLOR)
  .map(([key, value]) => {
    const cssText = convert2Css(value);

    return `:root[theme=${key}]{${cssText}}`;
  })
  .join('');

addStyle(themeStyle);

export const HASHED_THEME = Object.entries(THEME_COLOR).reduce(
  (m, [key, value]) => ({
    ...m,
    [key]: Object.entries(value).reduce(
      (s, [name, color]) => ({
        ...s,
        [name]: `#${color.replace(/(\d+),?\s*/g, (m, p1) =>
          Number(p1).toString(16).padStart(2, '0')
        )}`,
      }),
      {}
    ),
  }),
  {} as typeof THEME_COLOR
);
