import { storage } from '@/util';

export const LS_USER_SESSION_ID = 'session_id';

const setSessionId = (id: string) => {
  storage[LS_USER_SESSION_ID] = id;
};

const getSessionId = () => storage[LS_USER_SESSION_ID] || '';

export { getSessionId, setSessionId };
