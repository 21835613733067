import { Button, message } from 'antd';
import ClipboardJS from 'clipboard';

const INVITE_LINK_PRE = 'https://chyshen.com/?invite-code=';

export const Invitation = ({ inviteCount = 0, inviteCode = '' }) => {
  const inviteLink = `${INVITE_LINK_PRE}${inviteCode}`;

  const handleCopy = () => {
    new ClipboardJS('.copy-share-link', {
      text: () => inviteLink,
    });
    message.success('Copied');
  };

  return (
    <article className="bg-white shadow pt-24 pb-20 lg:px-32 sm:px-20">
      <h1 className="leading-none text-20 font-semibold mb-24">
        Invite friends
      </h1>
      <section className="flex sm:flex-col items-center">
        <div className="border border-primary border-opacity-60 bg-primary bg-opacity-6 text-primary text-center h-[166px] w-[211px] sm:mb-20">
          <div className="text-[40px] font-bold mt-40">{inviteCount}</div>
          <div className="text-12">YOU HAVE INVITED</div>
        </div>
        <div
          className={
            'border border-gray-border ' +
            'lg:py-20 lg:px-32 lg:ml-16 lg:h-[166px] ' +
            'sm:px-16 sm:py-12 '
          }>
          <div className="text-16 mb-12">Your referral link</div>
          <div className="lg:flex mb-18">
            <div
              className={
                'bg-gray-background h-36 flex items-center text-12 text-gray-mid pl-14 whitespace-nowrap mr-16 ' +
                'lg:w-[380px] ' +
                'sm:w-full'
              }>
              {inviteLink}
            </div>
            <Button
              type="primary"
              className="h-36 w-[102px] font-medium copy-share-link sm:mt-10"
              onClick={handleCopy}>
              Copy link
            </Button>
          </div>
          <div className="text-12 text-gray-dark text-opacity-50">
            Referrer will share a proportion of all fees incurred by your
            friends.
          </div>
        </div>
      </section>
    </article>
  );
};
