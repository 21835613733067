import {
  QueryClient,
  UseQueryOptions,
  UseQueryResult,
  QueryKey,
} from 'react-query';

declare module 'react-query' {
  // https://github.com/tannerlinsley/react-query/issues/1675#issuecomment-883820172
  export function useQueries<
    TQueryFnData = unknown,
    TError = unknown,
    TData = TQueryFnData,
    TQueryKey extends QueryKey = QueryKey
  >(
    queries: UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>[]
  ): UseQueryResult<TData, TError>[];
}

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchInterval: false,
      retry: 0,
      cacheTime: 3000,
      staleTime: 3000,
    },
  },
});

export const queryCache = queryClient.getQueryCache();

export default queryClient;
