import { useQuery } from 'react-query';
import { request } from '@/util';

// 30 days
const SESSION_STALE_TIME = 1000 * 60 * 60 * 24 * 30;

export const useUser = () =>
  useQuery<{
    id: string;
    email: string;
    estimate_price: number;
    features: string[];
    invite_code: string;
    invite_count: number;
  }>('user', () => request.get('/user'), {
    // }>('user', () => Promise.resolve({
    //   id: '123',
    //   email: '3344',
    //   estimate_price: 0,
    //   features: [],
    //   invite_code: '123',
    //   invite_count: 1,
    // }), {
    retry: 0,
    staleTime: SESSION_STALE_TIME,
  });
