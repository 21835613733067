import { Button, Divider, Form, Input, message } from 'antd';
import { useMutation } from 'react-query';

import { request } from '@/util';

const FeaturesForm = ({
  onFeaturesSet,
}: {
  onFeaturesSet(arg: { features: string[] }): void;
}) => {
  const { isLoading, mutate } = useMutation(
    (features: string[]) =>
      request.post('/suggest', { features: JSON.stringify(features) }),
    {
      onSuccess(_, features) {
        message.success('Submitted');
        onFeaturesSet({ features });
      },
    }
  );

  const handleSubmit = async ({ features }: { features: string[] }) => {
    mutate(features);
  };

  return (
    <Form
      initialValues={{ features: Array(3).fill('') }}
      onFinish={handleSubmit}>
      <Form.List
        name="features"
        rules={[
          {
            validator: async (_, features) => {
              if (!features || features.every((x: string) => !x)) {
                throw new Error('At least 1 feature');
              }
            },
          },
        ]}>
        {(fields, _, { errors }) => (
          <>
            <div>
              {fields.map((field, i) => (
                <Form.Item {...field}>
                  <Input placeholder={`Feature ${i + 1}`} maxLength={280} />
                </Form.Item>
              ))}
            </div>
            <Form.ErrorList errors={errors} />
          </>
        )}
      </Form.List>
      <Form.Item>
        <Button
          loading={isLoading}
          type="primary"
          htmlType="submit"
          className="h-[42px] w-[144px] font-bold text-16">
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

const FeaturesDisplay = ({ features }: { features: string[] }) => (
  <div className="text-15 text-gray-dark">
    <div className="mb-14">Your suggested features:</div>
    {features
      .filter((x) => x)
      .map((f, i) => (
        <div key={i}>
          {i + 1}. {f}
        </div>
      ))}
  </div>
);

type FeaturesProps = {
  features?: string[];
  onFeaturesSet(arg: { features: string[] }): void;
};

export const Features = ({ features, onFeaturesSet }: FeaturesProps) => {
  const isFeatureSet = features?.length;

  return (
    <article
      className={
        'bg-white pt-24 pb-20 shadow ' +
        'lg:mb-40 lg:px-32 ' +
        'sm:mb-24 sm:px-20 '
      }>
      <h1 className="leading-none text-20 font-semibold mb-24">
        Tell us your most wanted 3 features
      </h1>
      {isFeatureSet ? (
        <FeaturesDisplay features={features} />
      ) : (
        <FeaturesForm onFeaturesSet={onFeaturesSet} />
      )}
      <Divider className="bg-gray-dark bg-opacity-10 mt-40" />
      <section className="text-gray-dark text-opacity-50 mt-[9px] text-12 leading-5">
        <div>
          Feature requests that meet below requirements will receive an extra bonus
        </div>
        <div>1. Adopted by Chyshen</div>
        <div>2. Highly valuable feature requests</div>
        <div>3. With only limited proposers</div>
      </section>
    </article>
  );
};
